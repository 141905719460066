<template>
  <section class="nzn-mb-16">
    <h2 class="nzn-text-secondary nzn-text-4xl nzn-my-10 nzn-font-bold">
      What kind of business are you?
    </h2>
    
    <sector-list></sector-list>
    
  </section>
  <entity-count v-if="isOneSectorSelected"/>
  <not-listed-email v-else/>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import EntityCount from "./EntityCount.vue";
import NotListedEmail from "./NotListedEmail.vue";
import SectorItem from "./subs/SectorItem.vue";
import SectorList from "./subs/SectorList.vue";

export default defineComponent({
  components: { SectorItem, NotListedEmail, EntityCount , SectorList },
  name: "SectorSelector",  
  computed:{
    ...mapState('sector', ['sectors','isOneSectorSelected']),    
  },  
});
</script>
