import { createStore } from 'vuex'
import sector from './sector'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */


  const Store = createStore({
    modules: {
      sector
    }
  })
  
  export default Store

