<template>
  <section class="nzn-mb-16">
    <h2 class="nzn-text-secondary nzn-text-4xl nzn-my-10 nzn-font-bold">
      {{questionText}}
    </h2>
    <div class="nzn-relative nzn-mb-6 nzn-border nzn-border-black nzn-rounded-lg nzn-pl-4 nzn-custom-class">
      <input
        placeholder="Type here"
        v-model="entity"
        type="number"
        @blur="handleBlur"
        @focus="handleFocus"
        class="nzn-text-gray-900 nzn-text-lg nzn-block nzn-w-3/4 nzn-pl-10 nzn-p-4 nzn-border-none focus-visible:nzn-outline-none nzn-bg-none"
      />
      <div
        class="nzn-absolute nzn-inset-y-0 nzn-right-[0] nzn-flex nzn-items-center p-3"
      >
        <button
          @click="handleSubmit"
          class="nzn-text-gray-500 nzn-flex nzn-h-full nzn-items-center p-3 nzn-px-8 nzn-rounded-r-lg"
        >
          Submit
          <svg
            class="nzn-w-5 nzn-h-5 nzn-pl-2 nzn-fill-white"
            height="10px"
            width="10px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 227.096 227.096"
            xml:space="preserve"
          >
            <g>
              <g>
                <polygon
                  points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 
                    146.933,181.902 152.835,187.811 227.096,113.55 		"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
export default defineComponent({
  name: "EntityCount",
  data(){
    return {
      isFocused: false
    }
  },
  computed:{
    ...mapGetters('sector', ['clickedSector', 'entityCount']),
    questionText(){
      console.log(this.clickedSector.short_code == 'breweries')
      let qText = 'How many '
      qText += this.clickedSector.unit_plural == 'people' || this.clickedSector.unit_plural == 'persons'? 'employees' : ( this.clickedSector.short_code == 'breweries' ? 'hectolitres/year' : this.clickedSector.unit_plural)
      qText +=  ' do you '
      qText +=  this.clickedSector.short_code == 'breweries' ? 'brew':'have'
      qText +=' ?'
      return qText
    },
    entity: {
      get(){
        return this.entityCount != 0 ?  this.entityCount : ''
      },
      set(data){
        this.setEntityCount(data)
      }
    }
  },
  methods:{
    ...mapActions('sector', ['openPriceResult', 'setEntityCount']),
    handleFocus(){
      this.isFocused = true
    },
    handleBlur(){
      this.isFocused = false
    },
    handleSubmit(){
      this.openPriceResult(true)
    }
  }
});
</script>

<style>
</style>