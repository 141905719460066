import api from "@/boot/axios";
export async function getAllSector(context) {
    api.get("/pricing/sectors")
        .then((response) => {
            context.commit("SET_SECTORS", response.data.data);
            context.commit("SET_COMPUTE_SECTORS", response.data.data);
            
            if(response.data.features){
              context.commit("SET_FEATURES", response.data.features);
            }
            if(response.data.countOfFeatures){
              context.commit("SET_COUNT_OF_FEATURES", response.data.countOfFeatures);
            }

            if(response.data.countOfUsers){
              context.commit("SET_COUNT_OF_USERS", response.data.countOfUsers);
            }

            if(response.data.countOfSites){
              context.commit("SET_COUNT_OF_SITES", response.data.countOfSites);
            }

            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.size > 0){              
              let sectorUid = urlParams.get('s')
              let prevSector = response.data.data.filter(s => s.uuid === sectorUid)
              if(prevSector.length > 0){              
                context.commit("SET_CLICKED_SECTOR", prevSector[0].id);
                context.commit("SET_SECTOR_SELECTOR", true);
              }
            }
        })
        .catch((error) => {
            console.log("beep", error);
        });
}

export function openPriceResult(context, data) {
    context.commit("SET_PRICE_DISPALY_OPEN", data);
    if(!data){
      context.commit("SET_ENTITY_COUNT", '');
    }
}

export function setClickedSector(context, data){
  context.commit("SET_CLICKED_SECTOR", data);
}

export function setEntityCount(context, data){
  context.commit("SET_ENTITY_COUNT", data);
}

export function setSectorSelector(context, data) {
  context.commit("SET_SECTOR_SELECTOR", data);
}

export function setLocale(context, data){
  context.commit("SET_LOCALE", data);
}

export function setSubSector(context, data) {
  context.commit("SET_SUB_SECTOR", data);
}

export function setQueryUuid(context, data){
  context.commit("SET_QUERYSTRING_UUID", data);
}