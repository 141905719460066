//import app from "./main.js";
import priceCalculator from "./price-calculator.js";

function getBaseUrl() {
  let scriptElement = document.getElementById("nzn-widget-script-price");
  let url = "";
  if (scriptElement) {
    let scriptId = document
      .getElementById("nzn-widget-script-price")
      .getAttribute("src");
    let pathparts = scriptId.split("/");
    url = pathparts[0].trim("/") + "//" + pathparts[2].trim("/") + "/";
  }
  return url;
}

function injectVueAppIntoShadowDom(containerId, vueApp) {
  var element = document.getElementById(containerId);
  if (typeof element != "undefined" && element != null) {
    setTimeout(() => {
      // Create new link Element
      const link = document.createElement("link");
      // set the attributes for link element
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = getBaseUrl() + "javascript.nzn-widget.css";
      // Append link element to HTML head
      document.getElementsByTagName("head")[0].appendChild(link);
      vueApp.mount(element);
    }, 100);
  }
}

document.addEventListener("DOMContentLoaded", function () {
  //injectVueAppIntoShadowDom("nzn-widget-container", app);
  injectVueAppIntoShadowDom("nzn-widget-price-calculator", priceCalculator);
  if(typeof Weglot !== "undefined"){
    Weglot.on("languageChanged", function(newLang, prevLang) {
      var event = new CustomEvent("globalLanguageChanged", { 'detail': newLang });
      document.dispatchEvent(event);
    })
  }
  
});
