<template>
    <div 
        class="nzn-flex nzn-flex-col nzn-rounded-lg nzn-overflow-hidden nzn-border-[1px] nzn-cursor-pointer"
        :class="{'nzn-border-black': clickedSector.id == sector.id, 'nzn-border-gray-200' : clickedSector.id != sector.id}"
    >
        <div class="nzn-flex-none nzn-h-24 nzn-relative">
            <img :src="sector.backgroundImg" :alt="sector.name" class="nzn-absolute nzn-inset-0 nzn-w-full nzn-h-full nzn-object-cover"/>
            <div v-if="!sector.active" class="
                nzn-bg-black
                nzn-w-full 
                nzn-h-full 
                nzn-inset-0 
                nzn-object-cover 
                nzn-opacity-40
                "
            ></div>
            <span v-if="!sector.active" class="nzn-text-orange-500 nzn-font-medium nzn-absolute nzn-top-1/3 nzn-left-1/2 nzn-transform nzn--translate-x-1/4 nzn--translate-y-3/4 nzn-w-full nzn-text-sm">Coming Soon</span>
            <div class="
                nzn-w-full 
                nzn-h-11 
                nzn-absolute
                nzn-bg-white 
                nzn-bottom-0 
                nzn-flex 
                nzn-justify-center 
                nzn-align-middle 
                nzn-items-center
                nzn-font-medium
                hover:nzn-text-secondary
                ">
                {{sector.name}}
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "SectorItem",
  props: ['sector'],
  computed:{
    ...mapState('sector', ['clickedSector'])
  }
});
</script>
