<template>
  <div class="nzn-container nzn-mx-auto">
    <calculator-heading />
    <sector-selector />
    <estimated-cost v-if="isOpenPrice && clickedSector.active"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import CalculatorHeading from './components/PriceCalculator/CalculatorHeading.vue'
import EstimatedCost from './components/PriceCalculator/EstimatedCost.vue'
import SectorSelector from './components/PriceCalculator/SectorSelector.vue'


import './index.css'


export default defineComponent({
  name: 'PriceCalculator',
  components :{
    CalculatorHeading,
    SectorSelector,
    EstimatedCost
  },
  computed : {
    ...mapState('sector', ['isOpenPrice', 'clickedSector'])
  },
  methods: {
    ...mapActions('sector', ['getAllSector'])
  },
  mounted(){
    this.getAllSector()
  }
})
</script>

<style>

</style>