<template>
  <section class="nzn-mb-16">
    <div class="nzn-flex nzn-flex-col nzn-align-middle nzn-items-center">
      <h2
        class="nzn-text-center nzn-text-primary nzn-text-4xl nzn-font-serif nzn-my-10 nzn-font-semibold"
      >
        Pricing
      </h2>
      <p class="nzn-text-lg nzn-font-serif nzn-text-left">
        Our pricing, like our solution, has been designed to fit the type and
        size of your business. Just tell us which sector your business operates
        in and how large it is, and we’ll show you your platform price and the
        features you’ll be able to use. Don’t see your business sector in the
        list below? Just enter your email address and we’ll be in touch. And
        don’t forget - everyone signing up gets their first 30 days free, so you
        get plenty of time to trial the platform.
      </p>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CalculatorHeading",
});
</script>
