<template>
  <section class="nzn-mb-16">
    <h2 class="nzn-text-primary nzn-text-4xl nzn-my-10 nzn-font-bold">
      Your features list and pricing
    </h2>

    <div
      class="nzn-bg-gradient-to-r nzn-from-green-grad-start nzn-to-blue-grad-end nzn-rounded-xl md:nzn-p-10 nzn-p-4"
    >
      <h2
        class="md:nzn-text-4xl nzn-text-2xl nzn-font-bold nzn-font-serif nzn-mb-2"
      >
        {{ clickedSector.name }} Package
      </h2>
      <p class="nzn-font-serif">
        Your Net Zero Now platform fee and features list is as follows:
      </p>

      <div class="md:nzn-flex nzn-justify-between nzn-my-8">
        <div class="md:nzn-w-1/2 nzn-w-full md:nzn-pb-0 nzn-pb-6">
          <h4 class="nzn-font-bold nzn-text-sm nzn-mb-4">DESCRIPTION</h4>
          <ul>
            <li class="nzn-flex nzn-items-start nzn-mb-4">
              <svg
                class="nzn-w-1/12 nzn-mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>cash-multiple</title>
                <path
                  d="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z"
                />
              </svg>
              <p class="nzn-w-8/12 nzn-font-serif" v-if="isContact">
                <strong class="nzn-text-lg">{{ cost }}   / <a href='https://netzeronow.org/contact'><span class="nzn-text-blue-800">Contact Us</span></a></strong>
              </p>
              <p class="nzn-w-8/12 nzn-font-serif" v-else>
                <strong class="nzn-text-lg"
                  >{{ cost }}/month
                  <span v-if="preferenceSite">per site</span></strong
                >
                <span class="nzn-block">billed annually (ex VAT)</span>
              </p>
            </li>

            <li class="nzn-flex nzn-items-start nzn-mb-4">
              <svg
                class="nzn-w-1/12 nzn-mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>account-multiple</title>
                <path
                  d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z"
                />
              </svg>
              <p class="nzn-w-8/12">
                <strong class="nzn-text-lg nzn-font-serif"
                  >{{ users }} users</strong
                >
              </p>
            </li>

            <li class="nzn-flex nzn-items-start nzn-mb-4">
              <svg
                class="nzn-w-1/12 nzn-mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>domain</title>
                <path
                  d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z"
                />
              </svg>
              <p class="nzn-w-8/12">
                <strong class="nzn-text-lg nzn-font-serif"
                  >{{ sites }} {{sites > 1 ? 'sites' : 'site'}}</strong
                >
              </p>
            </li>
          </ul>
          <div class="nzn-mb-2">
            <button
              @click="goToBookADemo"
              class="nzn-mr-2 nzn-text-base md:nzn-mb-0 nzn-mb-4 nzn-bg-teal-500 md:nzn-px-4 nzn-px-3 nzn-py-3 nzn-rounded-full nzn-border nzn-border-green-700 hover:nzn-bg-secondary hover:nzn-text-white nzn-font-bold"
            >
              {{bookDemo}}
            </button>
            <button
              @click="goToGetStarted"
              class="nzn-mr-2 nzn-text-base md:nzn-px-4 nzn-px-3 nzn-py-3 nzn-rounded-full nzn-border nzn-border-green-700 hover:nzn-bg-secondary hover:nzn-text-white hover:nzn-border-white nzn-font-bold"
            >
              Get started*
            </button>            
          </div>
          <p class="nzn-font-serif nzn-text-base nzn-inline-block">
              *{{ clickedSector.trialDays }} day free trial available now!
          </p>
          
        </div>

        <div
          class="md:nzn-w-1/2 nzn-w-full"
          v-if="currentFeatures && currentFeatures.length > 0"
        >
          <h4 class="nzn-font-bold nzn-text-sm nzn-mb-6">With the Net Zero Now platform you can:</h4>
          <ul>
            <li
              v-for="feature in currentFeatures"
              :key="feature"
              class="nzn-mb-3 nzn-flex nzn-justify-start nzn-items-center nzn-font-serif"
            >
              <span class="nzn-w-5 nzn-mr-4">
                <svg
                  class="nzn-h-5 nzn-text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <title>check</title>
                  <path
                    d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                  />
                </svg>
              </span>
              <div>{{ feature }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="partners && partners.length > 0">
        <h4 class="nzn-font-bold nzn-text-sm">INDUSTRY PARTNERS</h4>
        <div class="nzn-grid md:nzn-grid-cols-6 nzn-grid-cols-4 nzn-gap-4">
          <img v-for="partner in partners" :src="partner.logo" :key="partner" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "EstimatedCost",
  data() {
    return {
      currentFeatures: [],
      cost: 0,
      tire: "Free Trial",
      users: "TBA",
      sites: "TBA",
      isContact: false,
      bookDemo: "Free Consultation"
    };
  },
  watch: {
    entityCount(n, o) {
      this.formatForDisplay();
    },
  },
  computed: {
    ...mapState("sector", [
      "clickedSector",
      "entityCount",
      "features",
      "countOfFeatures",
      "countOfUsers",
      "countOfSites",
    ]),
    preferenceSite() {
      if (this.clickedSector.unit_singular == "person") {
        return false;
      } else {
        return true;
      }
    },
    partners() {
      if (
        this.clickedSector.partners &&
        this.clickedSector.partners.length > 0
      ) {
        return this.clickedSector.partners;
      }
      return [];
    },
  },
  methods: {
    goToBookADemo() {
      console.log('book demo')
      window
        .open(
          this.clickedSector.bookDemoLink,
          "_blank"
        )
        .focus();
    },
    goToGetStarted() {
      window
        .open(
          process.env.VUE_APP_GET_STARTED +
            "?sector=" +
            this.clickedSector.short_code +
            "&qty=" +
            this.entityCount,
          "_blank"
        )
        .focus();
    },
    formatForDisplay() {
      const pricings = this.clickedSector.pricing;
      let tire = "POA";
      let price = 0;
      let featureCount = 0;
      let features = [...this.features];

      for (const eachPrice of pricings) {
        if (eachPrice.quantity > this.entityCount) {
          tire = eachPrice.type;
          price = eachPrice.price;
          break;
        }
      }
      this.tire = tire;

      if (price == 0) {
        this.cost = "Price on Application";
        this.isContact = true
      } else {
        this.cost = "£" + parseFloat(price / 12).toFixed(2);
        this.isContact = false
      }

      featureCount = this.countOfFeatures[tire];
      console.log(tire, featureCount);
      features.splice(featureCount);
      this.currentFeatures = features;

      this.users = this.countOfUsers[tire];
      this.sites = this.countOfSites[tire];

      this.bookDemo = (this.clickedSector.short_code == "salons") ? "Free Consultation" : this.bookDemo;
    },
  },
  mounted() {
    this.formatForDisplay();
  },
});
</script>

<style></style>
