<template>
  <section class="nzn-mb-16" v-if="!isThankYouShow">
    <h2
      class="nzn-text-secondary nzn-text-4xl nzn-my-10 nzn-font-bold"
    >
      Not in the list above?
    </h2>
    <P>Please enter your details below, </P>
    <div class="nzn-relative nzn-mb-6 nzn-border nzn-rounded-lg nzn-pl-4"
      :class="{'nzn-border-black nzn-custom-class': isFocused, 'nzn-border-gray-100': !isFocused}">
      <div
        class="nzn-absolute nzn-inset-y-0 nzn-left-0 nzn-flex nzn-items-center nzn-pl-3 nzn-pointer-events-none"
      >
        <svg
          aria-hidden="true"
          class="nzn-w-5 nzn-h-5 nzn-text-gray-500 dark:nzn-text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
          ></path>
          <path
            d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
          ></path>
        </svg>
      </div>
      <input
        type="text"
        class="nzn-text-gray-900 nzn-text-lg nzn-block nzn-w-3/4 nzn-pl-10 nzn-p-4 nzn-border-none focus-visible:nzn-outline-none nzn-bg-none"
        placeholder="example@email.com"
        @blur="validateEmail"
        @focus="handleFocus"
        v-model="email"
      />
      <div
        class="nzn-absolute nzn-inset-y-0 nzn-right-[0] nzn-flex nzn-items-center p-3"
      >
        <button
          class="nzn-text-gray-500 nzn-flex nzn-h-full nzn-items-center p-3 nzn-px-8 nzn-rounded-r-lg"
          @click="handleSubmit"
        >
          Submit
          <svg
            class="nzn-w-5 nzn-h-5 nzn-pl-2 nzn-fill-gray-700"
            :class="{'nzn-fill-white':isFocused}"
            height="10px"
            width="10px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 227.096 227.096"
            xml:space="preserve"
          >
            <g>
              <g>
                <polygon
                  points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 
                    146.933,181.902 152.835,187.811 227.096,113.55 		"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <span class="nzn-text-red-600">{{this.emailMsg}}</span>
  </section>
  <section class="nzn-mb-16" v-else>
    <span class="nzn-bg-green-100 nzn-text-green-800 nzn-text-2xl nzn-font-medium nzn-mr-2 nzn-px-2.5 nzn-py-0.5 nzn-rounded dark:nzn-bg-gray-700 dark:nzn-text-green-400 nzn-border nzn-border-green-400">
      Thank you for submitting your request, We will contact you soon
    </span>
  </section>
</template>

<script>
import api from "@/boot/axios";

import { defineComponent } from "vue";
export default defineComponent({
  name: "NotListedEmail",
  data() {
    return {
      email: "",
      emailMsg: "",
      isFocused: false,
      isThankYouShow: false
    }
  },
  methods: {
    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.emailMsg = ''
      } else {
        this.emailMsg = 'Invalid Email'
      }
      this.isFocused = false
    },
    handleFocus(){
      this.emailMsg = ''
      this.isFocused = true
    },
    handleSubmit(){
      if(this.emailMsg == ''){
        this.isThankYouShow = true
         api.post("/pricing/no-sector-email", {email: this.email})
        .then((response) => {
            this.isThankYouShow = true
        })
        .catch((error) => {
            console.log("beep", error);
        });
      }
    }
  },
});
</script>

<style>


.nzn-custom-class button{background:#000; color:#fff;}
</style>