<template>
    <div class="nzn-grid md:nzn-grid-cols-5 nzn-grid-cols-2 nzn-gap-4">      
    <sector-item
        v-for="sector in sectors"
        :key="sector.id"
        :sector="sector"
        @click="selectSector(sector.id)"
        v-if="sectors.length > 0"
    />
    <p class="nzn-text-2xl nzn-font-serif nzn-my-10 nzn-font-semibold" v-else> Coming Soon </p>
    </div>
    <button v-show="isSubSector"
        class="nzn-text-gray-500 nzn-flex nzn-h-full nzn-items-center nzn-py-8 nzn-rounded-r-lg"
        @click="handleBack"
    >
    ← Back
    
    </button>
</template>

<script>
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import SectorItem from "./SectorItem.vue";

export default defineComponent({
  name: "SectorList",
  components: { SectorItem },  
  computed:{
    ...mapState('sector', ['computeSectors','clickedSector','locale','isSubSector']),
    sectors: {
      get () { return this.$store.getters['sector/formatSectorList'] }
    },
  },
  mounted () {
    this.currentLang()
  },
  methods: {
    ...mapActions('sector', ['setClickedSector','setSectorSelector','setLocale','setSubSector','openPriceResult']),
    selectSector(id) { 
      let sector =   this.sectors.filter((s) => {
        return (s.id == id)
      })      
 
      if(sector[0].sub_sector_count > 0){
        this.$store.commit('sector/SET_SECTORS', sector[0].sub_sectors)
        this.setSubSector(true)
        this.setSectorSelector(false)
      }else{       
        //this.setSubSector(false)
        this.setSectorSelector(true)
        this.setClickedSector(id)
      }  
      this.openPriceResult(false)
    },
    handleBack(){
        this.$store.commit('sector/SET_SECTORS', this.computeSectors) 
        this.setSubSector(false)
        //this.setSectorSelector(false)
    },
    async handleLangChange ($e) {
      const lang = $e.detail
      this.setLocale(lang)
    },
    currentLang(){      
      if(window.Weglot){
        this.setLocale(window.Weglot.getCurrentLang())
      }
    }
  },
  created: function () {
    document.addEventListener('globalLanguageChanged', this.handleLangChange)
  },

  unmounted: function () {
    document.removeEventListener('globalLanguageChanged', this.handleLangChange)
  },
});
</script>
