export function SET_SECTORS(state, data) {
    state.sectors = data;
}

export function SET_COMPUTE_SECTORS(state, data) {
    state.computeSectors = data;
}

export function SET_PRICE_DISPALY_OPEN(state, data) {
    state.isOpenPrice = data;
}

export function SET_CLICKED_SECTOR(state, data){
    const currentSector = state.sectors.filter(function(sector){
        return sector.id === data
    });
    state.clickedSector = currentSector[0];
}

export function SET_ENTITY_COUNT(state, data){
    state.entityCount = data
}

export function SET_FEATURES(state, data){
    state.features = data
}

export function SET_COUNT_OF_FEATURES(state, data){
    state.countOfFeatures = data
}

export function SET_COUNT_OF_USERS(state, data){
    state.countOfUsers = data
}

export function SET_COUNT_OF_SITES(state, data){
    state.countOfSites = data
}

export function SET_SECTOR_SELECTOR(state, data){
    state.isOneSectorSelected = data
}

export function SET_LOCALE(state, data) {
    state.locale = data;
}

export function SET_SUB_SECTOR(state, data){
    state.isSubSector = data
}