export function clickedSector(state) {
    return state.clickedSector
}

export function entityCount(state){
    return state.entityCount
}

export function isOneSectorSelected(state){    
    return state.clickedSector != {}
}

export function formatSectorList(state) {
    let code = (state.locale === 'fr') ? 'FR' : 'GB'
    if(state.isSubSector){
        return state.sectors.filter(s => s.country.some(c => c.code === code)).sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
    }else{
        return state.computeSectors.filter(s => s.country.some(c => c.code === code)).sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
    }
}