import { createApp, h } from 'vue';
import VueGtag from 'vue-gtag';
import priceCalculator from './PriceCalculator.vue'
import store from './store';


const PriceCalculator  = createApp({
    render: ()=>h(priceCalculator)
}).use(store).use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG_ID }
});

export default PriceCalculator;
