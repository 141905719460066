export default function () {
  return {
    sectors: [],
    computeSectors: [],
    clickedSector: {},
    isOpenPrice: false,
    entityCount:0,
    features: [],
    countOfFeatures: {},
    countOfUsers: {},
    countOfSites: {},
    isOneSectorSelected: false,
    isSubSector: false,
    locale:'en'
  }
}
